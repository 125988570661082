import { createSelector } from 'reselect';
import { convertFromRaw } from 'draft-js';
import { CAPTURE_INTEGRATIONS } from '@/storychief/constants/Constants';
import getWordCount from '../../editor/utils/getWordCount';
import { SEO_MIN_SCORE } from '@/stories/constants/Constants';

const getStory = (state) => state.story;
const getStories = (state) => state.stories;
const getStoryShareReport = (state) => state.story.share_report;
const getStoryContent = (state) => state.story.content;
const getStoryAnalysis = (state) => state.story.analysis;
const getStoryStatusProperty = (state) => state.story.status_property;
const getStoryId = (_, props) => props.storyId;

export const getUsedTopicSuggestions = (state) => state.story.usedTopicSuggestions;

export const getStoryMonthlyPublishedLimitReached = createSelector(
  getStory,
  (story) => !story.published && !!story.destinations.filter((sd) => sd.limit_reached).length,
);

export const getStoryById = createSelector(getStoryId, getStories, (storyId, stories) =>
  stories.find((story) => story.id === storyId),
);

export const getStoryShareReportEnabled = createSelector(
  getStoryShareReport,
  (storyShareReport) => {
    if (storyShareReport) {
      return storyShareReport.enabled;
    }
    return false;
  },
);

export const getStoryContentState = createSelector(getStoryContent, (storyContent) => {
  let contentState = null;
  if (storyContent !== '') {
    contentState = convertFromRaw(JSON.parse(storyContent));
  }
  return contentState;
});

export const getStoryWordCount = createSelector(getStoryContentState, (storyContentState) => {
  let contentWordCount = 0;
  if (storyContentState) {
    contentWordCount = getWordCount(storyContentState);
  }
  return contentWordCount;
});

export const getStoryBriefIsProcessing = createSelector(
  getStoryStatusProperty,
  (storyStatusProperty) => storyStatusProperty === 'brief',
);

export const getStoryCollaboratorsIsProcessing = createSelector(
  getStoryStatusProperty,
  (storyStatusProperty) => storyStatusProperty === 'collaborators',
);

export const getStoryReviewsIsProcessing = createSelector(
  getStoryStatusProperty,
  (storyStatusProperty) => storyStatusProperty === 'reviews',
);

function getStoryAnalysisChartCircleClass(score) {
  if (score < SEO_MIN_SCORE.ok) {
    return 'circle circle-red';
  }
  if (score < SEO_MIN_SCORE.good) {
    return 'circle circle-orange';
  }
  return 'circle circle-green';
}

export const getStoryAnalysisChart = createSelector(getStoryAnalysis, (storyAnalysis) => {
  const seoScore = storyAnalysis.seo.score;
  const readabilityScore = storyAnalysis.readability.score;
  const seoChartCircleDash = (seoScore / 100) * 75;
  const readabilityChartCircleDash = (readabilityScore / 100) * 75;

  return {
    seo: {
      circleDash: seoChartCircleDash,
      circleClass: getStoryAnalysisChartCircleClass(seoScore),
    },
    readability: {
      circleDash: readabilityChartCircleDash,
      circleClass: getStoryAnalysisChartCircleClass(readabilityScore),
    },
  };
});

export const getStoryIsScheduled = createSelector(getStory, (story) => story.scheduled);

export const getStoryIsPublished = createSelector(getStory, (story) => story.published);

export const getStoryHasFeaturedImage = createSelector(
  getStory,
  (story) => !!(story.featured_image.src.length > 0),
);

export const getStoryHasCaptureIntegrations = createSelector(
  getStory,
  (story) =>
    !!(
      story.integrations &&
      story.integrations.length &&
      story.integrations.find(
        (integration) => CAPTURE_INTEGRATIONS.indexOf(integration.integration_type) !== -1,
      )
    ),
);

export const getStoryReportImpressions = createSelector(getStory, (story) => {
  let impressions = null;
  if (story.report && story.report.summary) {
    story.report.summary.forEach((metric) => {
      if (metric.name === 'views' || metric.name === 'reads') {
        impressions += metric.value;
      }
    });
  }
  return impressions;
});
